<template>
    <div>
        <input @change="uploadFile" :name="name" type="file" id="actual-btn" hidden />
        <div class="file-container">
          <span id="chosen-file">
                <span v-if="!fileName"> Завантажте фото</span>
                <span v-else style="white-space: nowrap;"> {{ fileName }}</span>
                <button v-if="resetBtn" class="reset-file" @click="clear">
                    <img src="@/assets/images/reset.png" alt="">      
                </button>
          </span>

            <label for="actual-btn">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.7166 10.5C14.0078 10.5 13.4332 11.0643 13.4332 11.7604V14.9791H2.56684V11.7604C2.56684 11.0643 1.99221 10.5 1.28342 10.5C0.574631 10.5 0 11.0643 0 11.7604V16.2396C0 16.9357 0.574631 17.5 1.28342 17.5H14.7166C15.4254 17.5 16 16.9357 16 16.2396V11.7604C16 11.0643 15.4254 10.5 14.7166 10.5Z"
                        fill="white"/>
                    <path
                        d="M10.5733 4.65604L9.15002 6.09593V1.28992C9.15002 0.577539 8.57914 0 7.87497 0C7.1708 0 6.59991 0.577539 6.59991 1.28992V6.09593L5.17661 4.65604C4.92768 4.40421 4.60135 4.27822 4.27502 4.27822C3.94869 4.27822 3.62241 4.40421 3.37343 4.65604C2.87552 5.15984 2.87552 5.97653 3.37343 6.48024L6.97338 10.1222C7.47129 10.6259 8.27865 10.6259 8.77656 10.1222L12.3765 6.48024C12.8745 5.97653 12.8745 5.15984 12.3765 4.65604C11.8786 4.15224 11.0712 4.15224 10.5733 4.65604Z"
                        fill="white"/>
                </svg>
            </label>
                
        </div>
          
    </div>
</template>

<script>
export default {
    name: "CustomInputFile",
    data() {
        return {
            fileName: "",
            resetBtn: false,
        }
    },
    props: {
        name: {
            type: String,
            require: false,
            default: 'file'
        }
    },
    methods: {
        uploadFile(e) {
            if (e.target.files) {
                this.fileName = e.target.files[0].name;
                this.resetBtn = true;
            } else {
                this.fileName = "";
            }
        },
        clear () {
           this.fileName = "";
           this.resetBtn = false;
        },
    }
}
</script>

<style scoped lang="less">
* {
    box-sizing: border-box;
}

.file-container {
    display: flex;
}
.reset-file {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #FFC900;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}
label {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background: #FFC900;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

#chosen-file {
    margin-right: 20px;
    height: 56px;
    max-width: 406px;
    width: 85%;
    background: #fff;
    border: 1px solid #FFC900;
    overflow: hidden;
    border-radius: 15px;
    padding: 18px 20px;
    /* display: flex;
    align-items: center;
    padding-left: 20px; */
    text-overflow: ellipsis;
    position: relative;
    @media all and(max-width: 768px) {
         width: 75%
    }
}
</style>
