<template>

    <div class="registartion">
        <div class="preloader" v-if="preloader">Завантаження...</div>
        <section class="wrapper" v-else>
             <CommonBlock title="Реєстрація" :visible-back-btn="false">
                 <div class="registartion-img">
                     <img src="../../assets/images/registration-img.svg" alt="registration-img">
                 </div>
             </CommonBlock>
            <div class="registartion-content content custom-scroll">
                <form class="registartion-form" @submit.prevent="registration">
                    <input type="hidden" name="token" v-model="$route.params.token">
                    <input type="hidden" name="area_id" v-model="form.area_id">
                    <input type="hidden" name="is_deputy" v-model="form.is_deputy">
                    <input type="hidden" name="is_party_member" v-model="form.is_party_member">
                    <input type="hidden" name="region_id" v-model="form.region_id">

                    <div class="avatar">
                        <label for="photo">
                            <img :src="form.photo || photo" alt="" v-if="form.photo || photo">
                        </label>
                        <input type="file" name="photo" id="photo" style="display: none!important;" @change="onFileChange">
                    </div>

                    <div class="registartion-form_inner">
                        <div class="registartion-form_group">
                            <InputComponent
                                v-model="form.first_name"
                                label="Ім’я"
                                name="first_name"
                                :required="checkFieldRequire('first_name')"
                                :error="errors.first_name"
                            />
                            <InputComponent
                                v-model="form.last_name"
                                label="Прізвище"
                                name="last_name"
                                :required="checkFieldRequire('last_name')"
                                :error="errors.last_name"
                            />
                            <InputComponent
                                v-model="form.middle_name"
                                label="По-батькові"
                                name="middle_name"
                                :required="checkFieldRequire('middle_name')"
                                :error="errors.middle_name"
                            />
                            <div class="registartion-form_field d-flex">
                                <div class="col">
                                    <DatepickerComponent
                                        label="Дата народження"
                                        :error="errors.birth_date"
                                        :required="checkFieldRequire('birth_date')"
                                        name="birth_date"
                                        v-model="form.birth_date"
                                    />
                                </div>
                                <div class="col">
                                    <CustomSelectV2
                                        label="Стать"
                                        :required="checkFieldRequire('gender')"
                                        v-model="form.gender"
                                        name="gender"
                                        :error="errors.gender"
                                        :options="[{label: 'Чоловіча', value: 0}, {label: 'Жіноча', value: 1}]"
                                    />
                                </div>
                            </div>
                            <InputComponent
                                v-model="form.education"
                                label="Освіта"
                                name="education"
                                :required="checkFieldRequire('education')"
                                :error="errors.education"
                            />
                            <InputComponent
                                v-model="form.register_address"
                                label="Місце проживання (реєстрації)"
                                name="register_address"
                                :required="checkFieldRequire('register_address')"
                                :error="errors.register_address"
                            />
                            <InputComponent
                                v-model="form.actual_address"
                                label="Місце фактичного проживання"
                                name="actual_address"
                                :required="checkFieldRequire('actual_address')"
                                :error="errors.actual_address"
                            />
                            <InputComponent
                                v-model="form.email"
                                label="Електронна адреса"
                                name="email"
                                type="email"
                                :required="checkFieldRequire('email')"
                                :error="errors.email"
                            />
                            <InputComponent
                                v-model="form.phone"
                                label="Номер телефону"
                                name="phone"
                                type="tel"
                                :required="checkFieldRequire('phone')"
                                :error="errors.phone"
                            />
                            <div class="registartion-form_field d-flex">
                                <div class="col">
                                    <InputComponent
                                        v-model="form.password"
                                        label="Ваш пароль"
                                        name="password"
                                        type="password"
                                        :required="checkFieldRequire('password')"
                                        :error="errors.password"
                                    />
                                </div>
                                <div class="col">
                                    <InputComponent
                                        v-model="form.password_repeat"
                                        label="Підтвердіть пароль"
                                        name="password_repeat"
                                        type="password"
                                        :required="checkFieldRequire('password_repeat')"
                                        :error="errors.password_repeat"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="registartion-form_group">
                            <InputComponent
                                v-model="form.card_id"
                                label="Номер партійного білету"
                                name="card_id"
                                autocomplete="off"
                                :required="checkFieldRequire('card_id')"
                                :error="errors.card_id"
                            />
                            <div class="registartion-form_field">
                                <CustomSelectV2
                                    label="Каденція"
                                    v-model="form.cadence"
                                    :error="errors.cadence"
                                    name="cadence"
                                    :required="checkFieldRequire('cadence')"
                                    :options="[{label: 'Вперше', value: 0}, {label: 'Повторна каденція', value: 1}]"
                                />
                            </div>
                            <InputComponent
                                v-model="form.commission"
                                label="Постійна комісія"
                                name="commission"
                                :required="checkFieldRequire('commission')"
                                :error="errors.commission"
                            />
                            <InputComponent
                                v-model="form.rada_position"
                                label="Посада в раді"
                                name="rada_position"
                                :required="checkFieldRequire('rada_position')"
                                :error="errors.rada_position"
                            />
                            <InputComponent
                                v-model="form.party_position"
                                label="Посада в партії"
                                name="party_position"
                                :required="checkFieldRequire('party_position')"
                                :error="errors.party_position"
                            />
                            <InputComponent
                                v-model="form.facebook"
                                label="Facebook"
                                name="facebook"
                                :required="checkFieldRequire('facebook')"
                                :error="errors.facebook"
                            />
                            <InputComponent
                                v-model="form.twitter"
                                label="Twitter"
                                name="twitter"
                                :required="checkFieldRequire('twitter')"
                                :error="errors.twitter"
                            />
                            <div class="registartion-form_field d-flex" style="margin-bottom: 0;">
                                <div class="col">
                                    <InputComponent
                                        v-model="form.instagram"
                                        label="Instagram"
                                        name="instagram"
                                        :required="checkFieldRequire('instagram')"
                                        :error="errors.instagram"
                                    />
                                </div>
                                <div class="col">
                                    <InputComponent
                                        v-model="form.youtube"
                                        label="YouTube"
                                        :required="checkFieldRequire('youtube')"
                                        name="youtube"
                                        :error="errors.youtube"
                                    />
                                </div>
                            </div>
                            <InputComponent
                                v-model="form.viber"
                                label="Viber"
                                name="viber"
                                type="tel"
                                :required="checkFieldRequire('viber')"
                                placeholder="+"
                                :error="errors.viber"
                            />
                            <div class="registartion-form_field d-flex">
                                <div class="col">
                                    <InputComponent
                                        v-model="form.telegram"
                                        label="Telegram"
                                        name="telegram"
                                        type="tel"
                                        :required="checkFieldRequire('telegram')"
                                        placeholder="+"
                                        :error="errors.telegram"
                                    />
                                </div>
                                <div class="col">
                                    <InputComponent
                                        v-model="form.whatsapp"
                                        label="Whatsapp"
                                        name="whatsapp"
                                        :required="checkFieldRequire('whatsapp')"
                                        type="tel"
                                        placeholder="+"
                                        :error="errors.whatsapp"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomButton label="Реєстрація"/>
                </form>
            </div>
        </section>

    </div>

</template>


<script>
import CustomButton from "@/components/CustomButton";
import CustomSelect from '../../components/CustomSelect'
import CustomInputFile from '../../components/CustomInputFile'
import AuthService from "@/services/AuthService";
import CustomSelectV2 from "../../components/ui/CustomSelectV2";
import CouncilService from "../../services/CouncilService";
import RegionService from "../../services/RegionService";
import CommonBlock from "../../components/CommonBlock";
import InputComponent from "@/components/ui/InputComponent";
import DatepickerComponent from "@/components/ui/DatepickerComponent";

export default {
    name: "register",

    data: () => ({
        preloader: true,
        date: new Date(),
        password_confirmation: null,
        photo: '',
        inputs: [
            {}
        ],
        errors: {},
        files: [],
        requireFields: [],
        councils: [],
        areas: [],
        regions: [],
        breadcrumbs: [
                {id: 1, name: 'Реєстрація'}
        ],
        form: {
            first_name: "",
            last_name: "",
            middle_name: "",
            card_id: "",
            birth_date: "",
            gender: "",
            is_deputy: "",
            is_party_member: "",
            education: "",
            phone: "",
            email: "",
            twitter: "",
            youtube: "",
            facebook: "",
            cadence: "",
            commission: "",
            rada_position: "",
            party_position: "",
            password: "",
            password_repeat: ''
        },
        recaptcha: '',
    }),
    methods: {
        pushInput() {
            this.inputs.push({})
        },
        async setCouncils() {
            let councils = await CouncilService.get();
            this.councils = councils.map(council => {
                return {
                    value: +council['key'],
                    label: council['value']
                };
            });
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();

            reader.onload = function (e) {
                this.photo = e.target.result;
            }.bind(this);
            reader.readAsDataURL(file);
        },
        async setRegions() {
            let regions = await RegionService.get([1]);
            this.regions = regions.results.map(region => {
                return {
                    value: +region['id'],
                    label: region['text']
                };
            });
        },
        async setAreas(cancelResetArea=false) {
            if(cancelResetArea !== true) {
                this.form.area_id = null;
            }
            let areas = await RegionService.get([3,4,5,6], this.form.region_id);
            this.areas = areas.results.map(area => {
                return {
                    value: +area['id'],
                    label: area['text']
                };
            });
        },
        async registration(e) {
            let resp = await AuthService.register(new FormData(e.target)).catch(this.catchValidationHandler);
            if(resp && resp.status === 'success') {
                location.href = '/login';
            }
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset()
        },
        previewFiles() {
            this.files = this.$refs.myFiles.files
        },
        catchValidationHandler(resp) {
            if(resp.statusCode === 422) {
                this.errors = resp.data;

                setTimeout(() => {
                    let errorLabels = document.getElementsByClassName('error-label');

                    if(errorLabels[0]) {
                        errorLabels[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    }
                }, 100);
            }
        },
        catchHandler(resp) {
            if(resp.statusCode === 422) {
                if(resp.data.token) {
                    location.href = '/';
                }
            }
        },
        checkFieldRequire(name) {
            return !!this.requireFields.find(field => field === name);
        }
    },

    async created() {
        await this.$recaptchaLoaded();
        let user = await AuthService.getUserForRegistration(this.$route.params.token).catch(this.catchHandler);
        if(user.user) {
            this.preloader = false;
            user.user.cadence = user.user.cadence === 'Обраний вперше' ? 0 : 1;
            this.form = Object.assign(this.form, user.user);
        }

        if(user.requiredFields) {
            this.requireFields = user.requiredFields;
        }

        await this.setCouncils();
        await this.setRegions();

        if(this.form.area_id) {
            await this.setAreas(true);
        }
    },
    components: {
        CustomButton,
        CustomSelect,
        CustomSelectV2,
        DatepickerComponent,
        InputComponent,
        CustomInputFile,
        CommonBlock
    }
};
</script>


<style scoped lang="less">
.avatar{
    margin: 0 auto 40px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #ffc900;
        transition: opacity .15s linear;
        display: block;
        position: relative;
        z-index: 2;
        object-fit: cover;
    }
    label{
        cursor: pointer;
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        padding-left: 0!important;
        &:hover{
            img{
                opacity: .4;
            }
            &:after, &:before{
                opacity: 1;
            }
        }
        &:after, &:before{
            content: '';
            display: block;
            position: absolute;
            transition: opacity .15s linear;
        }
        &:before{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 50%;
            z-index: 2;
            border: 2px dotted #CDD4E0;
        }
        &:after{
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #F5F5F5 url(../../assets/images/camera.svg) center center no-repeat;
            background-size: 26px 26px;
        }
    }
}
.preloader{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-field, .radio-field, .radio-group {
    display: flex;
}

.registartion {
    .wrapper {
        display: flex;
        overflow-y: auto;
        max-width: 100%;
        @media all and(max-width: 950px) {
            display: block!important;
        }
    }
    &-img {
        display: flex;
        height: 100%;
        align-items: center;
        img{
            max-width: 307px;
            width: 100%;
            height: auto;
        }
    }

}
.registartion-img{
    max-width: 320px;
    width: 100%;
    height: auto;
    margin: 70px 0;
}

form{
    max-width: 1040px;
}

section.wrapper .content{
    padding-top: 46px;
    @media all and (max-width: 950px) {
        padding-top: 40px;
        overflow: hidden;
    }
}

.input-field {
    margin-bottom: 20px;
}

.form-inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}

.form-group {
    width: 45%;
    justify-content: center;
}
.registartion-form_field{
    margin-bottom: 20px;
}

.registartion {
    .wrapper {
        display: flex;
        overflow-y: auto;
        max-width: 100%;
    }

    &-title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -0.392px;
        color: #fff;
        margin-bottom: 144px;
    }

    &-description {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.14px;
        color: #fff;
        margin-bottom: 20px;
    }

    &-form {
        max-width: 1040px;
        margin: 0 auto;
        &_inner {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media all and(max-width: 768px) {
                display: block;
            }
        }
        .col{
            position: relative;
        }
        &_group {
            width: calc(50% - 40px);
            @media all and(max-width: 1200px) {
                width: calc(50% - 20px);
            }
            @media all and(max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }
        }

        button {
            margin: 0 auto;
            display: block;
        }
    }
}
.d-flex {
    display: flex;
    justify-content: space-between;
    @media all and(max-width: 1350px) {
        display: block;
    }
}

.col {
    width: 48%;
    @media all and(max-width: 1350px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.avatar label {
    padding-left: 0;
}
</style>

<style lang="less">
.registartion .common-block .title{
    margin-top: 60px;
}
</style>
